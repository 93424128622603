$blue: #03334e
$white: #ffffff
$sans-font: "mr-eaves-xl-sans", 'Helvetica', sans-serif
$serif-font: 'YoungSerif', 'Georgia', serif

// breakpoints
$small: 500px
$tablet: 700px
$desktop: 1020px

@mixin wrapper
  width: 100%
  padding-right: 8%
  padding-left: 8%
  max-width: 1600px
  margin-right: auto
  margin-left: auto

@mixin margin-cen
  margin-right: auto
  margin-left: auto

@mixin text-shadow
  text-shadow: 1px 1px 5px $blue

@mixin above($breakpoint)
  @media screen and (min-width: $breakpoint)
    @content

*, *::before, *::after
  box-sizing: border-box
  margin: 0
  padding: 0

html, body
  &.modal-active
    overflow: hidden

body
  border: 0
  font: inherit
  font-size: 16px
  vertical-align: baseline
  overflow-x: hidden
  line-height: 1em
  position: relative
  background-color: $blue
  font-family: $sans-font
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  font-family: sans-font

img
  width: 100%
  display: inline-block
  vertical-align: top
  font-size: 0

h1, h2, h3, h4, h5, h6
  font-family: $serif-font
  color: $white
  line-height: 1.2em
  margin-bottom: 1rem

h1
  font-size: 2.25rem
  +above(500px)
    font-size: 2.35rem
  +above($tablet)
    font-size: 2.75rem

h2
  font-size: 1.6rem
  font-family: $sans-font

ul
  list-style-type: none
  margin: 0
  padding: 0
  li
    margin: 0
    padding: 0

p, li, a, address
  color: white
  font-family: $sans-font
  font-weight: 400
  font-size: 1.15rem
  font-style: normal

p, li
  line-height: 1.65em
  margin: 0 0 1rem
  font-kerning: normal
  span, a
    font-size: inherit
    line-height: inherit

a
  text-decoration: none
  display: inline-block

.wrap
  +wrapper()

.featured-image
  background-size: cover
  background-position: center center
  background-repeat: no-repeat
  display: block
  min-height: clamp(500px, 110vh, 710px)
  max-height: 710px
  width: 100%
  position: absolute
  top: 0
  left: 0
  z-index: 1
  opacity: .9
  transition: opacity 200ms ease-out

header, main, footer
  position: relative
  z-index: 2

header
  padding: 2.25rem 0 5.5rem
  text-align: center
  +above($tablet)
    padding-bottom: 7.5rem
  +above($desktop)
    padding-bottom: 9rem

.header-logo img
  max-width: 195px

// Video Prompt
.video-prompt
  margin-bottom: 4.5rem
  display: flex
  align-items: center
  justify-content: center
  +above($tablet)
    margin-bottom: 5.5rem
  +above($desktop)
    margin-bottom: 7rem

  img
    max-width: 65px
    margin-bottom: 1rem
  span
    +text-shadow()
  
  .modal-trigger
    display: inline-flex
    flex-direction: column
    align-items: center

// Introduction
.introduction
  text-align: center
  margin-bottom: 5rem
  h1
    +text-shadow()
  p
    max-width: 460px
    +margin-cen()

// Gallery
.gallery
  display: grid
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr))
  gap: 65px
  margin-bottom: 5.5rem

.gallery-image
  box-shadow: 1px 1px 10px 2px rgba(0,0,0,.15)

// Footer
.footer-introduction
  text-align: center
  margin-bottom: 3rem
  +above($tablet)
    margin-bottom: 4rem
  p
    max-width: 460px
    +margin-cen()

.details
  display: flex
  flex-wrap: wrap
  +above($tablet)
    justify-content: center

  span.title
    font-family: $serif-font
    font-size: 1.15rem
    margin-bottom: 1rem
    display: block
  
  > *:not(:last-child)
    margin-bottom: 2rem
    +above($tablet)
      margin-right: 1rem
      margin-bottom: 0
  
  address, ul
    text-align: center
    flex: 1 0 100%
    +above($tablet)
      flex: 0 0 40%
      max-width: 200px
      
  address
    line-height: 1.35em
    a
      line-height: 1.55em
      span
        display: inline-block
        border-bottom: 1px solid rgba(white, 0)
        padding-bottom: 2px
        line-height: 1
        transition: border-bottom 200ms ease-out
      &:hover
        span
          border-bottom: 1px solid rgba(white,1)
  
  ul
    li
      line-height: 1.4em
      &:nth-child(n+2)
        margin-bottom: 3px
      &:last-child
        margin-bottom: 0
      
      a
        position: relative
        &::after
          content: " "
          display: block
          height: 1px
          width: 100%
          background-color: white
          position: absolute
          bottom: 0
          left: 0
          opacity: 0
          transition: opacity 200ms ease-out
        &:hover 
          &::after
            opacity: 1

.credit
  padding: 6rem 0 2rem
  text-align: center
  p, a
    margin-bottom: 0
    font-size: 1rem
    color: #cecece
  
  span.sep
    padding: 0 1rem

// Modal
.modal
  position: fixed
  left: 0
  top: 0
  width: 100%
  height: 100%
  background-color: rgba($blue,0)
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  z-index: -1
  opacity: 0
  visibility: visible
  transition: all .15s ease-in-out
  cursor: pointer
  &.active
    background-color: rgba($blue,.85)
    z-index: 2
    opacity: 1
    visibility: visible

.modal-close
  position: fixed
  height: 100%
  width: 100%
  top: 0
  left: 0

.featured-video
  position: relative
  cursor: default
  max-width: 980px
  width: 80%
  height: auto
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin: 0 auto
  z-index: 2

  video
    width: 100%

// Chocolat Gallery
.chocolat-overlay
  background-color: $blue
  opacity: .95

.chocolat-wrapper
  .chocolat-bottom
    background: rgba($blue, .9)
    > *
      color: white
      font-family: $sans-font